/* CustomAlert.css */

.custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Higher z-index to appear above other content */
  }
  
  .custom-alert {
    background: white; /* White background for the alert */
    padding: 20px 100px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .custom-alert h2 {
    margin: 0 0 10px;
    color: #333; /* Dark text color */
  }
  
  .custom-alert p {
    margin-bottom: 20px;
    color: #000; /* Gray text color */
  }
  
  .alert-close-button {
    background-color: #461600; /* Blue background for button */
    color: white; /* White text color */
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .alert-close-button:hover {
    background-color: #7E4200; /* Darker blue on hover */
  }
  
  @media (max-width: 768px) {
    .custom-alert {
      background: white; /* White background for the alert */
      padding: 20px 60px;
      border-radius: 15px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      text-align: center;
    }
  }