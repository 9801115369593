* {
    box-sizing: border-box;
    margin: 0;
  }
  
  :root {
    --heights: 100vh;
    --widths: 100%;
  }
  
  .slider-container {
    height: var(--heights);
    width: var(--widths);
    position: relative;
    margin: auto;
    overflow: hidden;
  }
  
  .active {
    display: inline-block;
  }
  
  .inactive {
    display: none;
  }
  
  .slides {
    height: var(--heights);
    width: var(--widths);
    position: relative;
  }
  
  .slide-image {
    width: 100%;
    height: 75%;
    position: absolute;
    object-fit: cover;
    /* opacity: 0.9; */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  }
  
  .slide-title,
  .slide-text {
    width: 100%;
    height: 100%;
    color: #ca922d;
    font-size: 40px;
    position: absolute;
    text-align: center;
    top: 30%;
    z-index: 10;
  }

  .slide-title2 {
    width: 100%;
    height: 100%;
    color: #ca922d;
    font-size: 60px;
    position: absolute;
    text-align: center;
    top: 20%;
    z-index: 10;
  }
  
  .slide-text {
    /* top: 65%; */
    font-size: 16px;
    width: 100%;
    height: 100%;
    color: #fff;
    text-shadow: 2px 2px #fd7e14;
    position: absolute;
    text-align: center;
    top: 35%;
    z-index: 10;
    padding: 150px;
    
  }
  
  .prev,
  .next {
    color: transparent;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 40px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    background: rgb(98,44,0);
background: linear-gradient(93deg, rgba(98,44,0,1) 6%, rgba(155,90,26,1) 76%);
    color: #fff;
  }
  
  .slider-container:hover .prev,.slider-container:hover .next {
    color: black
  }
  
  .slider-container:hover .prev:hover,
  .slider-container:hover .next:hover {
    color: rgb(98,44,0);
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
  
  .next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
  }
  
  .all-dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 70%;
    justify-content: center;
    z-index: 999999999999999;
  }
  
  .dot {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: transparent;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border-radius: 50%;
    display: inline-block;
  }
  
  .slider-container:hover .dot:hover {
    background-color: rgba(98, 44, 0, 0.589);
  }
  
  .slider-container:hover .dot{
    background-color: rgba(0, 0, 0, 0.3);
  }
  .slider-container:hover .active-dot{
    background-color: rgba(98, 44, 0, 0.589);
  }
  
  .play-pause {
    color: black;
  }
  
  
  /* Media queries for smaller screen sizes */
  @media only screen and (max-width: 768px) {
    .slide-text {
      padding: 50px; 
      top: 40%;
    }
  }
  
  /* Media queries for even smaller screen sizes */
  @media only screen and (max-width: 480px) {
    .slide-title {
      font-size: 30px; 
    }
  
    .slide-text {
      padding: 50px; 
      top: 45%;
      font-size: 13px;
    }

    .prev,
    .next {
      color: transparent;
      cursor: pointer;
      z-index: 100;
      position: absolute;
      top: 40%;
      width: auto;
      padding: 0.5rem;
      margin-top: -3rem;
      font-size: 30px;
      font-weight: bold;
      border-radius: 0px 5px 5px 0px;
      background: #000089;
      background: rgb(98, 44, 0);
      background: linear-gradient(93deg, rgba(98, 44, 0, 1) 6%, rgba(155, 90, 26, 1) 76%);
      color: #fff;
    }
  
  }
  
  @media only screen and (max-width: 320px) {
    .slide-text {
      padding: 50px; 
      top: 45%;
      font-size: 12px;
    }

    .slide-title {
      font-size: 20px;
  }

    .slide-title2 {
font-size: 40px;
    }

    .prev,
  .next {
    color: transparent;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 0.5rem;
    margin-top: -3rem;
    font-size: 30px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    background: #000089;
    background: rgb(98, 44, 0);
    background: linear-gradient(93deg, rgba(98, 44, 0, 1) 6%, rgba(155, 90, 26, 1) 76%);
    color: #fff;
  }
  }