.meet-our-partners-container {
    margin-top: 32px;
    padding-top: 48px;
    padding-bottom: 30px;
  }
  
  @media (min-width: 768px) {
    .meet-our-partners-container {
      padding-bottom: 75px;
    }
  }
  
  .partners-heading-container {
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
  }
  
  .partners-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Poppins', sans-serif;
    transition: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform 200ms;
    margin-bottom: 16px;
    text-transform: capitalize;
    display: inline-block;
  }
  
  
  
  @media (min-width: 768px) {
    
  }
  
  .marquee-container {
    margin-top: 48px;
  }
  
  .marquee {
    overflow: hidden;
    display: flex;
  }
  
  .marquee-content {
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 16px;
    animation: marquee 65.6s linear infinite;
  }
  
  @media (min-width: 768px) {
    .marquee-content {
      gap: 16px;
    }
  }
  
  .partner-logo-container {
    background-color: white;
    padding: 4px;
    margin: 0 8px;
    width: 150px;
    height: 150px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 13px #B6BEFC;
    overflow: hidden;
  }
  
  @media (min-width: 768px) {
    .partner-logo-container {
      margin: 0 16px;
      width: 170px;
      height: 170px;
    }
  }
  
  .partner-logo {
    width: 100%;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  