

.common-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    /* padding-top: 200px; */
    background: #000000c0;
    border-radius: 20px;
    color: #fff;
  }
  
  .common-container p, ul, li{
    padding: 10px;
  }
  
  strong{
    color: #924834;
  }

  @media (min-width: 480px) {
    .common-container {
     margin: auto;
      width: 80%;

    }
  }