/* @import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Pacifico&family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Dancing+Script:wght@400..700&family=Great+Vibes&family=Pacifico&family=Sacramento&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Cinzel+Decorative:wght@400;700;900&display=swap");

body {
  background: #EDEDED;
  margin: 0;
  background-image: url("../src/components/images/bgg3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

p {
  font-family: "Cardo";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  text-align: justify;
  
}

.neonText {
  color: #924834;
  /* text-shadow: 0 0 7px #000, 0 0 10px #000, 0 0 21px #fff, 0 0 42px #000,
    0 0 82px #d98911, 0 0 92px #d98911, 0 0 102px #d98911, 0 0 151px #d98911; */
}

/* Base styles for the title */
.title {
  font-family: "Cinzel Decorative";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  font-size: 44px;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
  justify-content: center;
}

/* Media query for tablet screens */
@media (max-width: 768px) {
  .title {
    font-size: 30px; /* Reduce font size for tablets */
    text-align: center; /* Center the title on tablets */
  }
}

/* Media query for mobile screens */
@media (max-width: 480px) {
  .title {
    font-size: 28px; /* Further reduce font size for mobile */
    text-align: center; /* Keep it centered on mobile */
    margin: 15px 0; /* Adjust margin for smaller screens */
  }
}

.up-two{
  padding-top: 180px;
}

/* Media query for mobile screens */

@media (max-width: 480px) {
  .up-two{
    padding-top: 124px;
  }
}


@media (max-width: 320px) {
  .up-two{
    padding-top: 72px;
  }
}



h1,
h2 {
  text-align: center;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0;
}

p {
  text-align: justify;
}

h1 {
  font-family: "Cinzel Decorative";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  font-size: 5.2rem;
  animation: pulsate 0.11s ease-in-out infinite alternate;
}

h2 {
  font-family: "Cinzel Decorative";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@media (max-width: 480px) {
  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 1rem;
  }
}

.heading2 {
  font-size: 24px; /* Default size for small screens */
}

@media (min-width: 480px) {
  .heading2 {
    font-size: 28px; /* Slightly larger size for screens above 480px */
  }
}

@media (min-width: 768px) {
  .heading2 {
    font-size: 32px; /* Larger size for tablet screens and above */
  }
}

@media (min-width: 1024px) {
  .heading2 {
    font-size: 36px; /* Even larger size for desktop screens */
  }
}

@media (min-width: 1440px) {
  .heading2 {
    font-size: 40px; /* For larger desktop screens */
  }
}

li, ul{
  text-align: justify;
}


/* h1, h2, h3 {
  text-align: center;
  background: linear-gradient(
    90deg, 
    #2C0000 0%, 
    #622C00 39%, 
    #9B5A1A 50%, 
    #7E4200 61%, 
    #461600 100%
  );
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: move 3s ease infinite;
} */

.head1 {
  text-align: center;
  background: linear-gradient(
    90deg, 
    #2C0000 0%, 
    #622C00 39%, 
    #9B5A1A 50%, 
    #7E4200 61%, 
    #461600 100%
  );
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: move 3s ease infinite;
}

.head2 {
  text-align: center;
  background: linear-gradient(
    90deg, 
    #d7d4d0 0%, 
    #622C00 39%, 
    #9B5A1A 50%, 
    #efebe7 61%, 
    #b37820 100%
  );
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: move 3s ease infinite;
}

@keyframes move {
  0% { background-position: -100% 0%; }
  100% { background-position: 100% 0%; }
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.styled-link {
  text-decoration: none;
}








.nav-logo {
  position: fixed;
  width: 100%;
  margin: auto;
  text-align: center;
  justify-content: center;
  background: #000000c0;
  /* background: #ffffffc0; */
  z-index: 999999;
}

.nav-logo img {
  width: 10%;
  /* border-radius: 50%; */
  -webkit-filter: drop-shadow(2.5px 2.5px 2.5px #000);
  filter: drop-shadow(2.5px 2.5px 2.5px #000);
}

@media (max-width: 480px) {
  .nav-logo img {
    width: 30%;
  }
}

.home-main-box {
  width: 100%;
  /* height: 500px; */
  margin: auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* padding-top: 200px; */
}

.home-main-box p {
  width: 80%;
  margin: auto;
  padding-bottom: 5px;
}


.boxx-02 p {
  width: 80%;
  margin: auto;
  text-align: center;
}

.boxx-02 h2 {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .boxx-02 p {
    width: 80%;
    margin: auto;
    text-align: justify;
  }
}

/* start */

.home-main-box2 {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
#gasses {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gas {
  --blur: 1.75rem;
  --box-blur: calc(0.5 * var(--blur));
  --glow: var(--color);
  --size: 12rem;
  align-items: center;
  border-radius: 12px;
  border: 4px solid currentColor;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.15), 0 0 0 2px rgba(0, 0, 0, 0.15),
    inset 0 0 var(--box-blur) var(--glow), 0 0 var(--box-blur) var(--glow);
  color: var(--color, white);
  display: inline-flex;
  flex-direction: column;
  /* font-family: system-ui, sans-serif; */
  height: var(--size);
  justify-content: space-around;
  padding: 1rem;
  width: var(--size);
  width: 50%;
  margin: auto;
  align-items: center;
  justify-content: center;
  font-family: "Cinzel Decorative";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

.number {
  font-weight: 700;
}

.symbol {
  font-size: 4rem;
  /* font-family: "Neon Glow"; */
  font-family: "Cinzel Decorative";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  text-shadow: 0 0 var(--blur) var(--glow);
}

.gas.ar {
  --color: #924834;
  --color: #000000c0;
  filter: brightness(125%);
  cursor: pointer;
}

@media (max-width: 786px) {
  .gas {
    width: 80%;
  }
  .symbol {
    font-size: 2.8rem;
    /* font-family: "Neon Glow"; */
    text-shadow: 0 0 var(--blur) var(--glow);
  }
}

@media (max-width: 480px) {
  .gas {
    width: 80%;
  }
  .symbol {
    font-size: 1.8rem;
    /* font-family: "Neon Glow"; */
    text-shadow: 0 0 var(--blur) var(--glow);
  }
}



/* end */

/* venu start */

.venue-container {
  display: flex;
  align-items: center;
  /* background-color: #f9f9f9; */
  /* border: 1px solid #ddd; */
  border-radius: 15px;
  padding: 16px;
  margin: 20px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.venue-icon {
  width: 60px; /* Adjust the size of the icon as needed */
  height: 60px;
  margin-right: 15px;
}

.venue-title {
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
}

.event-details{
  color: #fff;
}

.venue-location {
  font-size: 1rem;
  color: #fff;
  margin-left: 5px;
}

/* Media Queries */
@media (max-width: 768px) {
  .venue-container {
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    align-items: center;
  }

  .venue-icon {
    margin-bottom: 10px;
  }

  .venue-title {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }

  .venue-location {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}

/* venue end */

/* start  */

.services-container {
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-columns: repeat(1, 1fr);
  width: 70%;
  margin: auto;
}

.service-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 8px;
  transition: transform 0.3s ease;
  text-decoration: none;
  color: inherit;

  background: transparent;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(22, 10, 38, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 0.8px solid rgba(22, 10, 38, 0.6);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(22, 10, 38, 0.6);
  transition: transform 0.3s ease;

  -webkit-box-shadow: 0px 0px 44px 3px rgba(184, 115, 51, 0.72);
  -moz-box-shadow: 0px 0px 44px 3px rgba(184, 115, 51, 0.72);
  box-shadow: 0px 0px 44px 3px rgba(184, 115, 51, 0.72);
}

.service-box:hover {
  transform: translateY(-5px);
}

.service-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
}

.service-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #924834;
}

@media (min-width: 320px) {

  .service-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}

  .services-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .services-container {
    width: 80%;
    margin: auto;
  }
}

@media (min-width: 600px) {
  .services-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .services-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* end */

/* party boxes start */

.party-boxes {
  display: grid;
  gap: 20px;
  padding: 20px;
  width: 80%;
  margin: auto;
}

.party-box {
  background: transparent;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(22, 10, 38, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 0.8px solid rgba(22, 10, 38, 0.6);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(22, 10, 38, 0.6);
  transition: transform 0.3s ease;

  -webkit-box-shadow: 0px 0px 68px 0px rgba(22, 10, 38, 0.6);
  -moz-box-shadow: 0px 0px 68px 0px rgba(22, 10, 38, 0.6);
  box-shadow: 0px 0px 68px 0px rgba(22, 10, 38, 0.6);
}

.party-box:hover {
  transform: translateY(-5px);
}

.party-box h2 {
  color: #924834;
}

.garden {
  /* background-image: url('https://example.com/garden.jpg');  */
  background-size: cover;
  background-position: center;
  color: #fff;
}

.pool {
  /* background-image: url('https://example.com/pool.jpg');  */
  background-size: cover;
  background-position: center;
  color: #fff;
}

.camping {
  /* background-image: url('https://example.com/camping.jpg');  */
  background-size: cover;
  background-position: center;
  color: #fff;
}

/* Media Queries */
@media (min-width: 768px) {
  .party-boxes {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  .party-boxes {
    grid-template-columns: 1fr;
  }
}

/* party boxes end */

/* card frame start */

.card {
  position: relative;
  width: 190px;
  height: 154px;
  background-color: #000000ab;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 12px;
  gap: 12px;
  border-radius: 8px;
  cursor: pointer;
}

.card::before {
  content: "";
  position: absolute;
  inset: 0;
  left: -5px;
  margin: auto;
  width: 100%;
  height: 164px;
  border-radius: 10px;
  background: linear-gradient(-45deg, #b87333 0%, #000000 100%);
  z-index: -10;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card::after {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: linear-gradient(-45deg, #b87333 0%, #000000 100%);
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
}

.heading {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
}

.card p:not(.heading) {
  font-size: 14px;
}

.card p:last-child {
  color: #fff;
  font-weight: 600;
}

.card:hover::after {
  filter: blur(30px);
}

.card:hover::before {
  transform: rotate(-90deg) scaleX(0.48) scaleY(2.34);
  border-radius: 15px;
}

@media (max-width: 320px) {
  .card::before {
    height: 214px;
  }

  .card:hover::before {
    transform: rotate(-90deg) scaleX(1.32) scaleY(1.04);
    border-radius: 15px;
  }
}

@media (max-width: 480px) {
  .card::before {
    height: 210px;
  }

  .card:hover::before {
    transform: rotate(-90deg) scaleX(0.85) scaleY(1.02);
    border-radius: 15px;
  }
}

@media (max-width: 800px) {
  .card::before {
    height: 212px;
  }

  .card:hover::before {
    transform: rotate(-90deg) scaleX(0.75) scaleY(1.32);
    border-radius: 15px;
  }
}

/* card frame end */

/* Container for the boxes */
.hm-highlights {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between the boxes */
  padding: 20px;
  width: 80%;
  margin: auto;
  text-align: center;
}

.hm-box {
  
  border-radius: 8px;
  padding: 20px;
 
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

/* Styling for the paragraphs inside the boxes */
.hm-box p {
  margin: 0 0 10px;
  text-align: center;
}

/* Media Queries */
@media (min-width: 768px) {
  .hm-highlights {
    flex-direction: row; /* Side by side on larger screens */
    justify-content: space-between;
  }

  .hm-box {
    flex: 1;
    max-width: 48%; /* Ensures each box takes roughly half the width */
  }
}

@media (max-width: 767px) {
  .hm-highlights {
    flex-direction: column; /* Stacked on small screens */
  }

  .hm-box {
    width: 100%;
  }
}


/* HighlightBoxes start */

.highlights-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  width: 60%;
  margin: auto;
}

.highlights-box {
  background-color: transparent;
  /* background: linear-gradient(-45deg, #b87333 0%, #000000 100%); */

  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  text-decoration: none;
  color: #333;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0px 0px 44px 3px rgba(184, 115, 51, 0.72);
  -moz-box-shadow: 0px 0px 44px 3px rgba(184, 115, 51, 0.72);
  box-shadow: 0px 0px 44px 3px rgba(184, 115, 51, 0.72);
}

.highlights-box:hover {
  transform: translateY(-5px);
}

.highlights-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 15px;
}

.highlights-heading {
  font-size: 1.2rem;
  padding: 10px;
  color: #924834;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 768px) {
  .highlights-container {
    grid-template-columns: 1fr;
    width: 80%;
  }
}

/* HighlightBoxes end */

/* schedule start */

.schedule-container {
  padding: 20px;
  border-radius: 10px;
  width: 60%;
  margin: auto;
  align-items: center;
  background: #00000081;
  color: #fff;
}

.schedule-heading {
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  font-size: 2rem;
}

.schedule-section {
  margin-bottom: 20px;
  padding: 15px;
  /* background-color: #ffffff; */
  border: 1px solid #2C0000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.schedule-section h2 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.schedule-section ul {
  list-style: none;
  padding-left: 0;
}

.schedule-section li {
  margin-bottom: 8px;
}

strong {
  color: #fff;
}

/* Media Queries */
@media (max-width: 768px) {
  .schedule-container {
    padding: 15px;
    width: 80%;
  }

  .schedule-heading {
    font-size: 1.8rem;
  }

  .schedule-section {
    padding: 10px;
  }

  .schedule-section h2 {
    font-size: 1.2rem;
  }
}




/* schedule end */

/* dj start */

.dj-container {
  padding: 20px;
  color: #fff;
  border-radius: 15px;
  width: 60%;
  margin: auto;
  align-items: center;
  background: #00000081;
  margin-top: 50px;
}



.dj-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.dj-description{
  text-align: center;
  margin: 8px;
}

.dj-card {
  background-color: #f1f1f1;
  text-align: center;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1.5px solid rgb(255, 255, 255);
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  min-width: 200px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.dj-card P{
  text-align: center;
}

.dj-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.more-btn {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #ff6347;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.more-btn:hover {
  background-color: #ff4500;
}

@media (max-width: 768px) {

  .dj-container {
    padding: 20px;
    color: #fff;
    border-radius: 15px;
    width: 80%;
    margin: auto;
    align-items: center;
    background: #00000081;
    margin-top: 50px;
  }

  .dj-list {
    flex-direction: column;
    align-items: center;
  }

  .dj-card {
    width: 100%;
    max-width: 400px;
  }
}




/* dj end */

/* stay start */

.stay-container {
  padding: 20px;
  border-radius: 10px;
  width: 60%;
  margin: auto;
  align-items: center;
  background: #00000081;
}

.stay-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 15px;
}

.stay-options {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.stay-card {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
 
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1.5px solid rgb(255, 255, 255);
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  min-width: 220px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.stay-card h3 {
  color: #fff;
}

@media (min-width: 320px) {
  .stay-container {
     
     width: 85%;
     margin: auto;
     
   }
 }

@media (max-width: 768px) {
  .stay-options {
    flex-direction: column;
  }

  .stay-card {
    margin-bottom: 20px;
  }
}

/* stay end */

/* food start */

.food-container {
  padding: 20px;
  border-radius: 10px;
  width: 85%;
  margin: auto;
  align-items: center;
  background: #00000081;
}

.food-description {
  font-size: 16px;
  margin-bottom: 15px;
  color: #fff;
}

.food-menu {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.menu-category {
  flex: 1;
  min-width: 220px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1.5px solid rgb(255, 255, 255);
  padding: 15px;
  border-radius: 8px;
  color: #fff;
}

.menu-category h3 {
  margin-bottom: 10px;
  color: #fff;
}

@media (max-width: 768px) {
  .food-menu {
    flex-direction: column;
  }

  .menu-category {
    margin-bottom: 15px;
  }
}

/* food end */

/* fire start */

.fire-camping-container {
  text-align: center;
  padding: 20px;
}

.party-image {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.party-description {
  font-size: 18px;
  color: #eee;
}

@media (max-width: 768px) {
  .party-description {
    font-size: 16px;
  }
}

/* fire end */

/* PoolParty start */

.pool-party-container {
  text-align: center;
  padding: 20px;
}

.party-image {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.party-description {
  font-size: 18px;
  color: #eee;
}

@media (max-width: 768px) {
  .party-description {
    font-size: 16px;
  }
}

/* PoolPartyend */

/* GardenParty start */

.garden-party-container {
  text-align: center;
  padding: 20px;
}

.party-image {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.party-description {
  font-size: 18px;
  color: #eee;
}

@media (max-width: 768px) {
  .party-title {
    font-size: 28px;
  }

  .party-description {
    font-size: 16px;
  }
}

/* GardenParty end */

/* more start */

.about-event-container {
  text-align: center;
  padding: 20px;
  /* padding-top: 200px; */
  width: 80%;
  margin: auto;
  background: #00000081;
  border-radius: 20px;
}

/* .event-image {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  margin-bottom: 20px;
} */

.event-description {
  font-size: 18px;
  color: #fff;

}

@media (max-width: 768px) {
  .event-title {
    font-size: 28px;
  }

  .event-description {
    font-size: 16px;
  }
}

.event-details {
  /* max-width: 800px; */
  margin: 0 auto;
  /* padding: 20px; */
  /* width: 80%; */
  margin: auto;
  color: #fff;
  text-align: justify;
}

.event-highlights,
.why-attend,
.event-packages {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.event-highlights li,
.why-attend li,
.event-packages li {
  margin: 10px 0;
  font-size: 1rem;
}

.divider {
  margin: 20px 0;
  border: 0;
  height: 1px;
  background-color: #924834;
}

.divider2{
  width: 60%;
  
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  height: 0.8px;
  background-color: #924834;
}

.divider3{
  width: 10%;
  
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  height: 100px;
  background-color: #1c08025b;
  border-radius: 20px;
}

.event-details h2 {
  margin-top: 20px;
  font-size: 1.5rem;
  color: #924834;
}

.event-details p,
.event-details a {
  font-size: 1rem;
  line-height: 1.6;
}

.event-details a {
  color: #924834;
  text-decoration: none;
}

.event-details a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .event-details {
    padding: 20px 40px;
  }
}

/* more end */

/* foote star */

.footer-container {
  background: #000000c0;
  color: #fff;
  padding: 20px 10px;
  border-top: 1px solid #924834;
  margin-top: 100px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  display: flex;
  align-items: center;
}
.footer-logo img {
  -webkit-filter: drop-shadow(5px 5px 5px #924834);
  filter: drop-shadow(5px 5px 5px #924834);
}

.logo-image {
  width: 150px; /* Adjust size as needed */
  height: auto; /* Maintains aspect ratio */
  margin-right: 10px;
}

.footer-links {
  display: grid;
  /* gap: 10px; */
  /* flex: 2; */
  justify-content: center;
}

.footer-link {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: color 0.3s;
  font-weight: 900;
  font-family: "Cardo";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

.footer-link:hover {
  color: #d98911;
}

.footer-social-media {
  display: flex;
  gap: 15px;
  flex: 1;
  justify-content: center;
}

.social-icon img {
  width: 24px;
  height: 24px;
  transition: transform 0.3s;
}

.social-icon:hover img {
  transform: scale(1.1);
}

.footer-contact {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  /* border-top: 1px solid #333; */
  padding-top: 10px;
  font-weight: 600;
  margin: auto;
  color:#924834;
}

.footer-icon {
  font-size: 35px;
  margin-right: 5px;
  color: #924834;
  box-shadow: #d98911 0px 5px 15px;
}

.footer-icon:hover {
  background-color: #924834;
  border: 1px solid #924834;
  color: #160a26;
  box-shadow: #924834 0px 5px 15px;
  border-radius: 12px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
  }

  .footer-contact {
    margin-top: 15px;
  }

  .footer-bottom {
    margin-top: 20px;
  }
}

/* footer end */
/* contact start */

.contact-us-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  padding: 20px;
  gap: 20px;
  width: 70%;
  margin: auto;
  margin-top: 20px;

  background: #000000c0;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(22, 10, 38, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 0.8px solid rgba(22, 10, 38, 0.6);
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0px 0px 68px 0px rgba(22, 10, 38, 0.6);

}

.contact-info {
  max-width: 400px;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.contact-info h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #fff;
}

.contact-info p {
  font-size: 1rem;
  margin: 10px 0;
  color: #fff;
  text-align: center;
}

.contact-info a {
  color: #924834;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-image {
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.contact-image .image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  border-radius: 15px;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

@media (min-width: 320px) {
  .contact-info p {
    font-size: 0.8rem;
    margin: 10px 0;
    color: #fff;
  }
}

@media (min-width: 480px) {
  .contact-info h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #fff;
  }

  .contact-info p {
    font-size: 1rem;
    margin: 10px 0;
    color: #fff;
  }
}

@media (min-width: 1024px) {
  .contact-info h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #fff;
  }

  .contact-info p {
    font-size: 1rem;
    margin: 10px 0;
    color: #fff;
  }
}

@media (min-width: 768px) {
  .contact-us-container {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    /* width: 90%; */
  }

  .contact-info {
    text-align: left;
  }

  .contact-image {
    margin-left: 20px;
    width: 60%;
  }

  .contact-info h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #fff;
  }

  .contact-info p {
    font-size: 1rem;
    margin: 10px 0;
    color: #fff;
  }
}

/* contact end */

/* NightlifeExperience start */

.NightlifeExperience-container {
  display: grid;
  gap: 20px;
  padding: 20px;
  width: 80%;
  margin: auto;
}

.NightlifeExperience-box {
  background: transparent;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(22, 10, 38, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 0.8px solid rgba(22, 10, 38, 0.6);
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0px 0px 68px 0px rgba(22, 10, 38, 0.6);

  /* Flexbox for centering content */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  /* Adjust box height to make vertical centering effective */
  min-height: 150px; /* Adjust as needed */
}

.NightlifeExperience-box:hover {
  transform: translateY(-5px);
}

/* Media queries for responsive design */
@media (min-width: 1024px) {
  .NightlifeExperience-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1023px) and (min-width: 600px) {
  .NightlifeExperience-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 599px) {
  .NightlifeExperience-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* NightlifeExperience end */


/* packages start  */

.packages-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.package-box, .early-bird {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background: #000000c0;
  color: #fff;
  /* display: grid; */
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.package-box, .early-bird p{
  text-align: center;
}



.book-button {
  background-color: #622C00;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 10px;
}

.book-button:hover {
  background-color: #b37820;
  transform: scale(1.05);
}

/* CSS */
.book-button {
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #fa8305b6;
  background-image: linear-gradient(45deg, #622C00 0%, #fa8305b6  51%, #622C00  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.book-button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.book-button:active {
  transform: scale(0.95);
}

/* ----------------------- */

.book-button2 {
  background-color: #622C00;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 10px;
}

.book-button2:hover {
  background-color: #b37820;
  transform: scale(1.05);
}

/* CSS */
.book-button2 {
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #fa8305b6;
  background-image: linear-gradient(45deg, #622C00 0%, #fa8305b6  51%, #622C00  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.book-button2:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.book-button2:active {
  transform: scale(0.95);
}

/* -------------------- */

.booking-platforms {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-top: 30px;
}

.platform-image {
  width: 150px;
  height: auto;
  border-radius: 15px;
  transition: transform 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.platform-image:hover {
  transform: scale(1.05);
}



@media (max-width: 768px) {

  .platform-image {
    width: 120px;
  }
}

@media (max-width: 480px) {

  .book-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .platform-image {
    width: 140px;
  }

  .booking-platforms {
    flex-direction: column;
    align-items: center;
  }
}


/* packages end */


/* -------slide show----------- */

.slideshow-main-div {
  margin: auto;
  position: relative;
  background-color: transparent;
  height: 600px;
  width: 100%;
  overflow: hidden;
}

.slideshow-image {
  width: 100%;
  height: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

@media (min-width: 320px) and (max-width: 480px) {
  .slideshow-main-div {
      margin: auto;
      position: relative;
      background-color: transparent;
      height: 180px;
      width: 100%;
      overflow: hidden;
  }
}

